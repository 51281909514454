<template>
  <div class="ca-page">
    <v-row class="ca-page-title"></v-row>
    <v-row class="ca-content px-1 px-sm-8 px-md-0">
      <v-col class="pr-md-16 align-self-start" md="6" cols="12">
        <h1 class="ca-title mb-6">You did it!</h1>
        <h3 class="ca-subtitle">
          Based on your creative analysis, this is a snapshot of who you are
          and what you're looking for in a creative campaign
        </h3>
        <p class="mt-6 mb-8">
          {{ creativeStatement }}
        </p>
        <h3 class="helper-text">But this is just the beginning!</h3>
      </v-col>
      <v-col class="pl-md-16 align-self-start" md="6" cols="12">
        <h2 class="ca-subtitle">If you'd like Sokal to follow up with</h2>
        <ul class="follow-up sokal">
          <li>Custom webslides</li>
          <li>Spot Examples</li>
          <li>Creative Strategy Statement</li>
        </ul>
        <p class="ca-paragraph">
          Fill out the form below and we'll send it your way!
        </p>
        <lead-form :statement="creativeStatement" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LeadForm from "../components/LeadForm.vue";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      snippets: {
        security:
          "Your number one priority is making sure your customers can rest assured with their purchase and service. ",
        passion:
          "You have a high value for helping your customers find and take care of their dream vehicle. ",
        simplicity:
          "It’s important to you that your customer’s experience is efficient, smooth, and simple. ",
        value:
          "You’ll beat any deal around with competitive incentives, discounts, and promotions. ",
        community:
          "Your customers are like family and having a local footprint in your community is important. ",
        playful: "You want to make a memorable and fun impression! ",
        lifestyle:
          "You want your customers to see their next vehicle as the perfect complement to their desired lifestyle. ",
        modern:
          "Getting the message across is important, but it should be brimming with style! ",
        established:
          "Stable and secure, you lean on national branding as a clean and reliable image. ",
        spokesperson:
          "A spokesperson embodies your values and provides a unified voice for your organization. ",
        "graphic-centered":
          "Your video content is a place for style, graphics, and creativity to shine! ",
        "deal card":
          "You make sure your deals and design match across platforms and highlight what matters! ",
        "on location shoot":
          "Seeing is believing! It’s important to give your customers an inside look into your dealership and surrounding area! ",
        classic:
          "Why reinvent the wheel? Inventory, deals, and location are important to you and they deserve the spotlight. ",
        storyteller:
          "You have a story to tell and a picture is worth a thousand words! What better way to tell your story than your advertising? ",
        trendsetter:
          "You’re willing to try something new and do what your competitors aren’t to push the envelope and make a big impression. ",
      },
    };
  },
  mounted () {
    window.scrollTo(0, 0);
    document.getElementById('app').scrollIntoView();
  },
  computed: {
    ...mapGetters(["getConclusion"]),
    creativeStatement() {
      return Object.values(this.getConclusion).reduce((string, value) => {
        return string + this.snippets[value];
      }, "");
    },
  },
  components: {
    LeadForm,
  },
};
</script>

<style scoped>
ul.sokal {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.sokal li {
  background: url("../assets/images/icons/orange-chevrons-smol.png") no-repeat left
    top / 16px;
  background-position: left center;
  padding-left: 35px;
  margin: 10px 0px;
}
@media (min-width: 960px) {
  .ca-content {
    padding-top: 5%;
  }
}
</style>
