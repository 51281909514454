export const clientEmail = ((name, dealer, spot, spotURL, banner, bannerImg, identifiers, overall, statement) => {
  return `
<!DOCTYPE html>
<html>
  <head>
    <style>
      .content {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        max-width: 600px;
        margin: auto;
        color: #171A20;
        background: #F8FDFF;
        font-size: 16px;
        padding: 30px;
      }
      .results {
        background: #EAF7FC;
        padding: 15px;
        border-radius: 12px;
        color: #171A20;
        text-align: center;
      }
      .results span, .results h3, .conclusion h3, .content h3 {
        color: #171A20;
        font-weight: 400;
      }
      @media (max-width: 599px) {
        .content {
          padding: 15px;
        }
      }
    </style>
  </head>
  <body>
    <div class="content">
      <h1 class="head" style="color: #171A20; font-weight: 400;">
        Thanks for taking the time to complete the Sokal Creative Analysis
        ${name}!
      </h1>
      <h3>We can't wait to to talk more about ${dealer}</h3>
      <h2 style="color: #828282">Here's what you selected</h2>
      <div class="results">
        <h3>Identifiers: <span style="text-transform: capitalize">${identifiers.join(', ')}</span></h3>
        <h3>Banner Type: <span style="text-transform: capitalize">${banner}</span></h3>
        <h3>Spot Type: <span style="text-transform: capitalize">${spot}</span></h3>
        <h3>Overall: <span style="text-transform: capitalize">${overall}</span></h3>
        <div class="spacer" style="height: 15px"></div>
        <h3>Review your video spot inspiration <a href="${spotURL}" target="_blank" style="color: #FF4438; cursor: pointer;">here</a>!</h3>
      </div>
      <div class="statement-section">
        <h2>Creative Strategy Statement</h2>
        <p class="client-statement">${statement}</p>
      </div>
      <div class="webslide-section" style="margin: 15px auto">
        <img style="height: auto; max-width: 100%;" src="${bannerImg}">
      </div>
      <div class="conclusion">
        <h3>Want to keep the conversation rolling? We’ve got plenty to work with! <a href="https://info.gosokal.com/creative-follow-up" target="_blank" style="color: #FF4438; cursor: pointer;">Click here</a> to schedule a follow up with our team.</h3>
      </div>
    </div>
  </body>
</html>

  `;
})