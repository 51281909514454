export const leadEmail = ((name, dealer, spot, banner, identifiers, overall, phone, email, statement) => {
  return `
<!DOCTYPE html>
<html>
  <head>
    <style>
      .content {
        font-family: "Avenir", Helvetica, Arial, sans-serif;
        margin: auto;
      }
      .results span {
        text-transform: capitalize;
      }
    </style>
  </head>
  <body>
    <div class="content">
      <h1 style="color: #171A20">New lead from Creative Analysis</h1>
      <p>Name: ${name}</p>
      <p>Dealership: ${dealer}</p>
      <p>Phone Number: ${phone}</p>
      <p>Email: ${email}</p>

      <ul class="results">
        <li>Spot Type: <span>${spot}</span></li>
        <li>Banner Type: <span>${banner}</span></li>
        <li>Identifiers: <span>${identifiers.join(', ')}</span></li>
        <li>Overall: <span>${overall}</span></li>
        <li>Statement: <span>${statement}</span></li>
      </ul>
    </div>
  </body>
</html>
`;
})