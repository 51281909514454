<template>
  <form @submit.prevent="submitLeads" id="creativeAnalysis">
    <v-text-field
      v-model="dealer"
      :error-messages="dealerErrors"
      label="Dealership Name"
      required
      @input="$v.dealer.$touch()"
      @blur="$v.dealer.$touch()"
    ></v-text-field>
    <v-text-field
      v-model="name"
      :error-messages="nameErrors"
      label="Your Name"
      required
      @input="$v.name.$touch()"
      @blur="$v.name.$touch()"
    ></v-text-field>
    <v-text-field
      v-model="email"
      :error-messages="emailErrors"
      label="E-mail"
      required
      @input="$v.email.$touch()"
      @blur="$v.email.$touch()"
    ></v-text-field>
    <vue-tel-input-vuetify
      v-model="phone"
      :error-messages="phoneErrors"
      label="Phone"
      required
      @input="$v.phone.$touch()"
      @blur="$v.phone.$touch()"
    ></vue-tel-input-vuetify>
    <v-btn
      color="#ff4438" large rounded
      class="start-btn submit-btn white--text"
      type="submit"
      :disabled="submitted === 'OK'"
    >
      Get Started
    </v-btn>
    <p color="#ff4438" class="mt-6" v-show="submitted === 'ERROR'">
      Please fill out the form correctly
    </p>
    <p class="mt-6" v-show="submitted === 'OK'">
      Thank you for completing the Creative Analysis. Your email with your results will be sent shortly.
    </p>
    <v-dialog v-model="dialog">
      <v-card>
        <div class="d-flex flex-no-wrap modal-content align-center">
          <v-img class="ma-6 mr-1" max-width="65" src="../assets/images/buttons/orange-chevrons.png" />
          <v-card-title class="text-h4 pr-6">You’ll hear from us soon!</v-card-title>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#ff4438" text @click="dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </form>
</template>

<script>
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { clientEmail } from "../emails/clientEmail";
import { leadEmail } from "../emails/leadEmail";

export default {
  mixins: [validationMixin],

  validations: {
    name: { required },
    email: { required, email },
    dealer: { required },
    phone: { required },
  },
  props: {
    statement: String,
  },
  data() {
    return {
      name: "",
      email: "",
      dealer: "",
      phone: "",
      submitted: "",
      dialog: false
    };
  },
  computed: {
    dealerErrors() {
      const errors = [];
      if (!this.$v.dealer.$dirty) return errors;
      !this.$v.dealer.required && errors.push("Dealership is required.");
      return errors;
    },
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.required && errors.push("Name is required.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.phone.$dirty) return errors;
      !this.$v.phone.required && errors.push("Phone is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push("Must be valid e-mail");
      !this.$v.email.required && errors.push("E-mail is required");
      return errors;
    },
    ...mapState(["identifiers", "spot", "overall", "banner", "bannerImg", "spotURL"]),
  },
  methods: {
    generateEmail() {},
    leadData() {
      return {
        leadEmail: ["ztitka@gosokal.com", "abardaxis@gosokal.com"],
        clientEmail: this.email,
        leadEmailHtml: leadEmail(
          this.name,
          this.dealer,
          this.spot,
          this.banner,
          this.identifiers,
          this.overall,
          this.phone,
          this.email,
          this.statement
        ),
        clientEmailHtml: clientEmail(
          this.name,
          this.dealer,
          this.spot,
          this.spotURL,
          this.banner,
          this.bannerImg,
          this.identifiers,
          this.overall,
          this.statement
        ),
        leadSubject: "Here's a fresh lead...",
        clientSubject: "Thanks for filling out the form",
      };
    },
    submitLead(recipient) {
      this.$http
        .post(
          "https://3wlhkpmphe.execute-api.us-east-1.amazonaws.com/test/lead_form",
          this.leadData(recipient)
        )
        .then((res) => {
          console.log(res);
        });
    },
    submitLeads() {
      if (this.$v.$invalid) {
        this.submitted = "ERROR";
        return;
      } else {
        this.submitted = "OK";
        this.dialog = true;
      }
      this.submitLead("sokalMessage");
    },
  },
  components: {
    VueTelInputVuetify,
  },
};
</script>

<style>
  .v-application .text-h4 {
    font-family: "Avenir", Helvetica, Arial, sans-serif !important;
    word-break: break-word;
  }
</style>
